import LinkReducer from "../LinkReducer/LinkReducer";

function Home() {
  return (
    <div>
      <LinkReducer />
    </div>
  );
}

export default Home;
