import "./UserCheckEmail.css"

function UserCheckEmail() {
  return (
    <div className="check-email__container">
      <h1 className="check-email__title">Check your Emails !</h1> 
    </div>
  );
};

export default UserCheckEmail;